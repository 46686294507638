import React from 'react';
import {Icon} from 'react-icons-kit';
import {check} from 'react-icons-kit/feather/check';
import {arrowRight} from 'react-icons-kit/feather/arrowRight';
import Container from 'common/components/UI/Container';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import Input from 'common/components/Input';
import {
  Section,
  ContentWrapper,
  SubscriptionWrapper,
  SubscriptionForm,
  Features,
} from './subscription.style';

const Subscription = () => {

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(e);
    const email = e.target[0].value;
    console.log('mail to send: ', email);

    try {
      const response = await fetch('/.netlify/functions/sendMail', {
        method: 'POST',
        body: JSON.stringify({email}),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.text();
      console.log('mail send: ', data);
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <Section>
      <Container>
        <ContentWrapper>
          <SubscriptionWrapper id="subscription">
            <Heading content="Orice produs poate fi vândut, alege să comunici direct cu audiența potrivită!
            Alege Applicat!"/>
            {/* <SubscriptionForm onSubmit={handleSubmit}>
              <Input
                type="email"
                className="input-field"
                placeholder="Email"
              />
              <Button
                type="submit"
                title="Înscrie-te pe Waitlist!"
                icon={<Icon size={18} icon={arrowRight}/>}
              />
            </SubscriptionForm> */}
            {/* <Features>
              <li>
                <Icon size={20} icon={check} /> 30 days money back
              </li>
              <li>
                <Icon size={20} icon={check} /> Cancel anytime
              </li>
              <li>
                <Icon size={20} icon={check} /> Support &amp; help
              </li>
            </Features> */}
          </SubscriptionWrapper>
        </ContentWrapper>
      </Container>
    </Section>
  );
};

export default Subscription;
