import React from 'react';
// import Button from 'common/components/Button';
import { useStaticQuery, graphql } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { ic_chevron_right } from 'react-icons-kit/md/ic_chevron_right';
import Container from 'common/components/UI/Container';
import Heading from 'common/components/Heading';
import Link from 'common/components/Link';
import Text from 'common/components/Text';
import GatsbyImage from 'common/components/GatsbyImage';

import {
  Section,
  ContentWrapper,
  Illustration,
  TextContent,
} from './advancedAnalytics.style';

import{
  ButtonGroup
} from '../Pricing/pricing.style'
import { buttonStyle } from 'styled-system';

const AdvancedAnalytics = () => {
  const data = useStaticQuery(graphql`
    query {
      analytics: file(
        relativePath: { eq: "image/saasMinimal2/analytics1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 716
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  const buttonStyle = {
    border: 'none',
    backgroundColor: 'rgb(237, 50, 55)',
    fontFamily: 'Poppins',
  };

  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
  };
  return (
    <Section>
      <Container>
        <ContentWrapper>
          <TextContent>
            <Text
              as="span"
              className="slogan"
              content="High Efficiency"
            />
            <Heading
              className="title"
              content="Soluție pentru creșterea rezultatelor strategiilor de marketing prin influenceri
              "
            />
            <Text
              className="desc"
              content="Planifică cele mai profitabile campanii de promovare, care generează vânzări, engagement și cresc atât audiența de clienți, dar și urmăritorii profilurilor de influenceri."
            />
           <ButtonGroup>
           {/* <button style={buttonStyle}><a href="#subscription" style={linkStyle}>Înscrie-te pe waitlist</a></button> */}
              {/* <Link className="link" href="#">
                See Pricing Plan <Icon size={18} icon={arrowRight} />
              </Link> */}
            </ButtonGroup>
          </TextContent>
          <Illustration>
            <GatsbyImage
              src={
                (data.analytics !== null) | undefined
                  ? data.analytics.childImageSharp.gatsbyImageData
                  : {}
              }
              alt="Illustration"
            />
          </Illustration>
        </ContentWrapper>
      </Container>
    </Section>
  );
};

export default AdvancedAnalytics;
