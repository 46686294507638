import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Container from "common/components/UI/Container";
import Image from "common/components/Image";
import Link from "common/components/Link";

import {
  Section,
  ContentWrapper,
  Copyright,
  Nav,
  SocialProfiles,
  Icons,
} from "./footer.style";

import logoDark from "common/assets/image/saasMinimal2/Logo1.png";
import image1 from "common/assets/image/logo_UE.png";
import image2 from "common/assets/image/logo_IS-2014-2020.png";
import image3 from "common/assets/image/sigla_guv_coroana_albastru.png";
import social1 from "common/assets/image/saasMinimal2/social/fb.png";
import social2 from "common/assets/image/saasMinimal2/social/instagram.png";
import social3 from "common/assets/image/saasMinimal2/social/tik-tok1.png";
import social4 from "common/assets/image/saasMinimal2/social/youtube.png";
import social5 from "common/assets/image/saasMinimal2/social/linkedin.png";
import {width} from "styled-system";

const Footer = () => {
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const openPrivacyModal = () => {
    setIsPrivacyModalOpen(true);
  };
  const closePrivacyModal = () => {
    setIsPrivacyModalOpen(false);
  };
  const data = useStaticQuery(graphql`
    query {
      saasMinimal2Json {
        footerNav {
          id
          url
          title
        }
        socialLinks {
          id
          link
          label
          icon {
            publicURL
          }
        }
      }
    }
  `);
  const divStyle = {
    display: "flex",
    justifyContent: "space-around",
    paddingBottom: "50px",
  };
  const paragraph = {
    fontSize: "25px",
  };
  const overlay = {
    position: "absolute",
    top: -200,
    left: 0,
    width: "100%",
    height: "100%",
    // backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backdropFilter: "blur(8px)",
  };
  const modal = {
    backgroundColor: "#fff",
    paddingTop: "40px",
    paddingLeft: "40px",
    paddingBottom: "40px",
    borderRadius: "4px",
    // maxWidth: '900px',
    width: "100%",
    maxHeight: "70vh",
    overflowY: "auto",
  };
  const modalButton = {
    padding: "8px 16px",
    backgroundColor: "#333",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    display: "block",
    margin: "0 auto",
  };
  return (
    <Section style={{ position: "relative" }}>
      <Container>
        <ContentWrapper>
          <Copyright>
            <figure>
              <Image src={logoDark} alt="saas minimal" />
            </figure>
            <p></p>
          </Copyright>
          {/* Copyright © 2023 <Link href="#">RedQ, Inc.</Link> */}
          <Nav>
            {data?.saasMinimal2Json?.footerNav?.map((item) => (
              <li key={item.id}>
                <Link href={item.url}>{item.title}</Link>
              </li>
            ))}
            <li>
              <Link href="#privacy" onClick={openPrivacyModal}>
                Privacy
              </Link>
            </li>
          </Nav>
          <SocialProfiles>
            <span>Social</span>
            {/* <Icons>
              {data?.saasMinimal2Json?.socialLinks?.map((item) => (
                <Link key={item.id} href={item.url}>
                  <Image src={item.icon.publicURL} alt={item.label} />
                </Link>
              ))}
            </Icons> */}
            <a href="https://www.facebook.com/profile.php?id=100090872510216">
              <img src={social1} alt="Facebook Profile" />
            </a>
            <a href="https://www.instagram.com/applicatro/">
              <img src={social2} alt="Facebook Profile" />
            </a>
            <a href="https://www.tiktok.com/@applicatro">
              <img src={social3} alt="Facebook Profile" />
            </a>
            <a href="https://www.youtube.com/@applicat">
              <img src={social4} alt="Facebook Profile" />
            </a>
            <a href="https://www.linkedin.com/company/applicatro">
              <img src={social5} alt="Facebook Profile" />
            </a>
          </SocialProfiles>
        </ContentWrapper>
      </Container>
      <Container>
        <ContentWrapper>
        <div>
          <p>
            „Proiect cofinanțat de Uniunea Europeană (UE) din Fondul European de
            Dezvoltare Regională (FEDR) prin Programul Operațional
            Competitivitate 2014-2020, ”Competitivi împreună”          </p>

            <p> Titlul proiectului: ”Platformă de inovare deschisă pentru
            gestionarea creativității colaborative în Marketingul Digital -
            AiMedia” Cod SMIS: 129318
            </p>

          <p>Parteneri: <a href="https://thecon.ro" target="_blank">THECON SRL</a> – lider de proiect și <a href="https://kinelink.ro/en/home/" target="_blank">KINELINK MOOVEE SRL</a> - partener </p>
          <div style={divStyle}>
            <img src={image1} style={{height: "110px"}} />
            <img src={image2} style={{height: "110px"}} />
            <img src={image3} style={{height: "110px"}} />
          </div>
        </div>
        </ContentWrapper>
      </Container>
      {isPrivacyModalOpen && (
        <div style={overlay} onClick={closePrivacyModal}>
          <div style={modal}>
            <h2>Privacy Policy</h2>
            <p style={paragraph}>
              Privacy Statement<br></br>
              1. Introduction<br></br>
              We understand the importance of your privacy and your concerns
              about the security of your personal information. As such, we have
              prepared this comprehensive Privacy Statement to demonstrate our
              commitment to privacy and security, and to comply with the various
              laws and regulations concerning data protection. This Privacy
              Statement applies to THECON SRL, with a principal place of
              business at Aleea Lalelelor, nr.10, Galați which owns and operates
              the website located at www.applicat.ro (the “Website”).<br></br>
              2. Types of Information We Collect We collect certain personal
              information to offer and fulfill our services. This can include:
              a) Personal identification information: Your first name, last
              name, and email address. We collect this information when you fill
              out our subscription form or contact form on the Website.<br></br>
              3. How We Use Your Personal Information The personal information
              we collect is primarily used to provide you with services you have
              requested or are part of our engagement with you. We also use
              personal information for the following purposes: a) Communication:
              We may use your personal information to communicate with you, for
              example, to notify you about changes to our services, to respond
              to inquiries or to send marketing materials. b) Improvement of
              Services: We may use your personal information to improve our
              services and website, including surveying users about potential
              features or improvements. c) Security: We may use your personal
              information to verify your identity and to check your
              qualifications, to protect the rights, property, or safety of us,
              our customers, or others.<br></br>
              4. How We Protect Your Personal Information The security of your
              personal information is paramount to us. We use appropriate data
              collection, storage, and processing practices, and security
              measures to protect against unauthorized access, alteration,
              disclosure, or destruction of your personal information.<br></br>
              5. Use of Cookies We may use cookies and other tracking
              technologies to collect and store your information. Cookies are
              small files that a site or its service provider transfers to your
              computer's hard drive through your web browser (if you allow) that
              enables the site's or service provider's systems to recognize your
              browser and capture and remember certain information.<br></br>
              6. Third-Party Websites Our Website may contain links to other
              sites that are not operated by us. If you click on a third-party
              link, you will be directed to that third party's site. We strongly
              advise you to review the Privacy Policy of every site you visit.
              <br></br>
              7. Your Choices and Rights You have certain rights in relation to
              your personal information under local data protection laws. These
              may include: the right to access your personal data; to have your
              personal data corrected; to receive a machine-readable copy of
              your personal data; and the right to object to us using your
              personal data for direct marketing.<br></br>
              8. Changes to This Privacy Statement We reserve the right to
              revise and update this Privacy Statement from time to time. Any
              changes will be effective immediately upon the posting of the
              revised Privacy Statement.<br></br>
              9. Contact Us If you have any questions or concerns about this
              Privacy Statement or our privacy practices, you may contact us at
              contact@thecon.ro. We will do our best to promptly respond to your
              inquiry and address your concerns. By using our Website, you
              acknowledge that you have read and understand this Privacy
              Statement. If you do not agree with our policies and practices,
              your choice is not to use our Website. We are committed to
              respecting and protecting your privacy and will ensure that all
              personal information provided to us is kept secure and
              confidential.
            </p>
            <button style={modalButton} onClick={closePrivacyModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </Section>
  );
};

export default Footer;
