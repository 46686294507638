import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Container from 'common/components/UI/Container';
// import Heading from 'common/components/Heading';
// import Button from 'common/components/Button';
import Text from 'common/components/Text';
import GatsbyImage from 'common/components/GatsbyImage';
import {
  Section,
  ContentWrapper,
  TextContent,
  Illustration,
  ButtonGroup,
} from './banner.style';

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      illustration: file(
        relativePath: { eq: "image/saasMinimal2/banner/Section.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1000 
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  const buttonStyle = {
    border: 'none',
    backgroundColor: 'white',
    fontFamily: 'Poppins',
  };

  const linkStyle = {
    color: 'rgb(237, 50, 55)',
    textDecoration: 'none',
  };
  return (
    <Section id="home">
      <Container>
        <ContentWrapper>
          <TextContent>
            {/* <Heading content="" /> */}
            <Text content="Applicat este platforma inovatoare de marketing digital pentru companii și influenceri care se folosește de un algoritm de inteligență artificială pentru a maximiza rezultatele campaniilor de promovare din social media.
" />
            <ButtonGroup>
              {/* <button style={buttonStyle}><a href="#subscription" style={linkStyle}>Înscrie-te pe waitlist</a></button> */}
              
              {/* <Text as="span" content="*No Credit card required" /> */}
            </ButtonGroup>
          </TextContent>
      <Illustration>
        <GatsbyImage
          src={
            (data.illustration !== null) | undefined
              ? data.illustration.childImageSharp.gatsbyImageData
              : {}
          }
          alt="Illustration"
        />
      </Illustration>
        </ContentWrapper>
      </Container>
    </Section>
  );
};

export default Banner;
