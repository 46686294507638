import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { arrowRight } from 'react-icons-kit/feather/arrowRight';
import { check } from 'react-icons-kit/feather/check';
import Container from 'common/components/UI/Container';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import Link from 'common/components/Link';
import Text from 'common/components/Text';
import GatsbyImage from 'common/components/GatsbyImage';

import {
  Section,
  SectionHeading,
  ContentWrapper,
  Illustration,
  TextContent,
  ButtonGroup,
  Features,
} from './pricing.style';

const Pricing = () => {
  const data = useStaticQuery(graphql`
    query {
      woman: file(relativePath: { eq: "image/saasMinimal2/Frame 86.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  const buttonStyle = {
    border: 'none',
    backgroundColor: 'rgb(237, 50, 55)',
    fontFamily: 'Poppins',
  };

  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
  };
  return (
    <Section id="pricing">
      <Container noGutter>
        <SectionHeading>
          <Heading content="Applicat schimbă complet abordarea asupra marketingului prin influenceri!" />
        </SectionHeading>
        <ContentWrapper>
          <TextContent>
            <Heading
              className="title"
              content="Cere o ofertă! 
              "
            />
            <Text
              className="desc"
              content="Cele mai bune strategii de marketing digital prin influenceri, maximizate de inteligența artificială. 

              Pentru branduri, influenceri și agenții. Contacteaza-ne agentul de vanzari prin email la contact@thecon.ro sau telefon +40757348085."
            />
            {/* <Features>
              <li>
                <Icon size={20} icon={check} /> <b>30 days money back</b>
              </li>
              <li>
                <Icon size={20} icon={check} /> <b>Cancel anytime</b>
              </li>
              <li>
                <Icon size={20} icon={check} /> <b>Support &amp; help</b>
              </li>
            </Features> */}
            <ButtonGroup>
            {/* <button style={buttonStyle}><a href="#subscription" style={linkStyle}>Înscrie-te pe waitlist</a></button> */}
              
              {/* <Link className="link" href="#">
                See Pricing Plan <Icon size={18} icon={arrowRight} />
              </Link> */}
            </ButtonGroup>
          </TextContent>
          <Illustration>
            <GatsbyImage
              src={
                (data.woman !== null) | undefined
                  ? data.woman.childImageSharp.gatsbyImageData
                  : {}
              }
              alt="Illustration"
            />
          </Illustration>
        </ContentWrapper>
      </Container>
    </Section>
  );
};

export default Pricing;
